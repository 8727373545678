<template>
  <div class="flex-center position-ref full-height">
    <div class="content">
      <Loading :loading="loading"></Loading>
      <img src="/assets/logos/news-o-matic.png">
      <h3 v-if="this.$route.query.sso">Lütfen bekleyiniz, ilgili platforma yönlendiriliyorsunuz.</h3>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "NOM",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      secretKey: 'Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy',
      userData: null,
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.sso) {
      this.loading = true
      // gelen data çözülüyor
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);
      this.ueslmsCheckUser();
    }
  },
  methods: {
    async goNOM() {
      await axios.post('https://www.ueslms.com/api/v2/nom/user/check', this.userData)
          .then(response => {
            // console.log('RES', response.data);
            window.location.href = response.data.url
          })
          .catch(response => {
            console.log('ERR', response)
            this.loading = false
          })
    },

    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/check2', {user: this.userData})
          .then(response => {
            if (response.data.success) {
              // user_id ekleniyor
              this.userData.user_id = response.data.user.user_id;
              // campus_id, ueslms id'si olarak degistiriliyor
              this.userData.campus_id = response.data.user.campus_id;
              this.goNOM()
            } else {
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/create', this.userData)
          .then(response => {
            // user_id ekleniyor
            this.userData.user_id = response.data.user.user_id;
            // campus_id, ueslms id'si olarak degistiriliyor
            this.userData.campus_id = response.data.user.campus_id;
            this.goNOM()
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },
  }
}
</script>
